/* eslint-disable react/prop-types */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { firebase, gtag } from './src/config';
import { FirebaseProvider } from '@churni/fire';
import { GlobalWrapper } from '@churni/common';
import { Helmet } from 'react-helmet';

import 'lazysizes';

export const wrapRootElement = ({ element }) => {
  // Lazy load firebase core (auth & analytics) and
  return (
    <FirebaseProvider config={firebase}>
      <Helmet>
        <script>dataLayer = []</script>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtag}');`}
        </script>
      </Helmet>
      {element}
    </FirebaseProvider>
  );
};

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.createRoot(container, {
      hydrate: true,
      hydrationOptions: { onHydrated: callback }
    }).render(element);
  };
};
